
import { Component, OnInit, Input, NgZone, Inject, ViewChild, ElementRef, } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import * as global from 'src/app/globals';
import { DOCUMENT, PlatformLocation } from '@angular/common';
import { Subscription } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import { DataService } from 'src/app/services/data.service';
import { ExternalService } from 'src/app/services/external.service';
import * as uuid from 'uuid';
import { DynamicPopupsModalComponent } from '../dynamic-popups-modal/dynamic-popups-modal.component';

declare const AdyenCheckout: any;

@Component({
    selector: 'app-payment-options-modal',
    templateUrl: './payment-options-modal.component.html',
    styleUrls: ['./payment-options-modal.component.scss'],
    standalone: false
})
export class PaymentOptionsModalComponent implements OnInit {
    @ViewChild('dropinModal') private dropinElement: ElementRef;
    public payPalConfig?: IPayPalConfig;

    @Input() public orderData;
    oemsDetail: any;
    languageData: any;
    paymentOptions: any;
    showSuccess = false;
    isProcessing: Boolean = false;
    thankyouPageUrl: any;
    dispalyPaypalBtn: Boolean = false;
    dispalyAdyenBtn: Boolean = false;
    dispalyInvoiceBtn: Boolean = false;
    dispalyInShopBtn: Boolean = false;
    paymentMethodsLength: any;
    id: any;
    adyenBtnImagePath: any;
    originKey: any;
    paymentMethodsRespons: any;
    currentPaymentType = "";
    isDisplayloaderMsg: Boolean = false;
    dicUrls: any;
    myAccountPageUrl: any;
    myOrderPageUrl: any;
    isPaymentOptions: Boolean = false;
    refusalReasonCode: any;
    uniqueId: any;
    isDisplayPaymentOptions: Boolean = false;
    isDisplayAdyenDropIn: Boolean = false;
    languageDataSubscription: Subscription;
    colourfulSpinner: any;
    constructor(@Inject(DOCUMENT) document, private location: PlatformLocation, private modalService: NgbModal, private ngZone: NgZone, private route: ActivatedRoute, private router: Router, private globalService: GlobalService, private dataService: DataService, public activeModal: NgbActiveModal, private readonly svc: ExternalService) {
        this.adyenBtnImagePath = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].RESOURCE_CONTAINER + 'email-templates-images/' + environment[global.env].oemCode + '/email_templates_payment_methods.png'
    }
    ngOnInit() {
        this.svc.lazyAdyenQuill().subscribe(_ => {
            this.setupQuill();
        });
        this.colourfulSpinner = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/icons/colourful_spinner.webm';
    }

    setupQuill() {
        if (this.globalService.gettLanguageData() != undefined) {
            this.languageData = this.globalService.gettLanguageData();
        }
        else {
            this.languageDataSubscription = this.globalService.languageDataEmiter.subscribe(data => {
                this.languageData = data;
            });
        }
        this.getOemSettings();
        this.initConfig();
        this.dataService.getOriginKeyData().subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            this.originKey = resJSON.originKeys[Object.keys(resJSON.originKeys)[0]];
            var arrLocaleCode = environment[global.env].arrAdyenLocaleCode;
            var currentLocale = arrLocaleCode[global.lang.toUpperCase()];
            this.dataService.getPaymentMethods(currentLocale, this.orderData.BillCountry).subscribe((res: Response) => {
                let resSTR = JSON.stringify(res);
                let resJSON = JSON.parse(resSTR);
                this.paymentMethodsRespons = resJSON;
                this.paymentMethodsLength = resJSON.paymentMethods.length;
                this.isPaymentOptions = true;
            });
        });
        this.dicUrls = this.globalService.getStaticPagesUrls(global.env, global.lang);
        this.myOrderPageUrl = this.dicUrls['ORDERS_PREVIEW'];
        this.thankyouPageUrl = this.dicUrls['THANKYOU_PREVIEW'];
    }

    getOemSettings() {

        this.dataService.getOemSettings().subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            this.oemsDetail = resJSON.data;
            var paymentOptions = this.oemsDetail.paymentOptions;
            this.paymentOptions = paymentOptions.split(',');
            for (let i = 0; i < this.paymentOptions.length; i++) {
                if (this.paymentOptions[i] == 'PayPal') {
                    this.dispalyPaypalBtn = true;
                }
                else if (this.paymentOptions[i] == 'Adyen') {
                    this.dispalyAdyenBtn = true;
                }
                else if (this.orderData.paymentType == 'instant') {
                    // if (this.paymentOptions[i] == 'Invoice' && this.orderData.PaymentMethod != "Invoice"
                    // ​   &&  this.orderData.PaymentStatus != "Paid") {
                    //     this.dispalyInvoiceBtn = true;
                    // }
                    // else if (this.paymentOptions[i] == 'In-Shop' && this.orderData.PaymentMethod != "Shop" 
                    // && this.orderData.PaymentStatus != "Paid") {
                    //     this.dispalyInShopBtn = true;
                    // }
                }
                else if (this.orderData.paymentType == 'repayment') {
                    if (this.orderData.isPickup == 'No') {
                        if (this.orderData.isHideInvoiceBtn) {
                            this.dispalyInvoiceBtn = false;
                        }
                        else {
                            this.dispalyInvoiceBtn = true;
                        }
                    }
                    else if (this.orderData.isPickup == 'Yes') {
                        this.dispalyInShopBtn = true;
                    }
                }
            }
            this.isDisplayPaymentOptions = true;
        });
    }

    updatePaymentOrderStatus(orderNumber, paymentMethod, paymentStatus, id) {
        this.dataService.updatePaymentOrder(orderNumber, paymentMethod, paymentStatus, id).subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            if (resJSON.success == true) {
                this.updateOrderStatus(orderNumber);
            }
        })
    }

    updateOrderStatus(orderNumber) {
        this.dataService.updateStatus(orderNumber).subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            if (resJSON.success == true) {
                this.closeModal();
                this.isProcessing = false;
                this.openThankyouModal();
            }
        });
    }

    openInvoiceModal() {
        let title = this.languageData.web.mycart_invoice_popup_title;
        let checkBoxtext = this.languageData.web.mycart_invoice_popup_msg;
        let cancelBtn = this.languageData.web.mycart_invoice_popup_cancel_btn;
        let okBtn = this.languageData.web.mycart_invoice_popup_ok_btn;
        this.closeModal();
        const modalRef = this.modalService.open(DynamicPopupsModalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
        modalRef.componentInstance.data = {
            title: title, cancelBtnTxt: cancelBtn, checkBoxtext: checkBoxtext, okBtnTxt: okBtn, modalName: 'invoiceModal', cancelIcon: false,
            orderData: this.orderData
        };
    }

    openThankyouModal() {
        let title = this.languageData.web.myorder_ins_pay_success_popup_tit;
        let subTitle = this.languageData.web.myorder_ins_pay_success_popup_msg;
        let okBtn = this.languageData.web.myorder_ins_pay_success_popup_ok;
        const modalRef = this.modalService.open(DynamicPopupsModalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
        modalRef.componentInstance.data = { title: title, subTitle: subTitle, okBtnTxt: okBtn, modalName: 'thankYou', cancelIcon: false };
        modalRef.componentInstance.variableEvent.subscribe((res) => {
            if (res) {
                window.location.reload();
               }
        })
    }

    openInShopModal() {
        let title = this.languageData.web.checkout_inshop_popup_title;
        let checkBoxtext = this.languageData.web.checkout_inshop_popup_msg;
        let cancelBtn = this.languageData.web.checkout_inshop_popup_cancel_btn;
        let okBtn = this.languageData.web.checkout_inshop_popup_ok_btn;
        this.closeModal();
        const modalRef = this.modalService.open(DynamicPopupsModalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
        modalRef.componentInstance.data = {
            title: title, cancelBtnTxt: cancelBtn, checkBoxtext: checkBoxtext, okBtnTxt: okBtn, modalName: 'inShopModal', cancelIcon: false,
            orderData: this.orderData
        };
    }


    openAdyenModal() {
        this.isDisplayAdyenDropIn = true;
        this.currentPaymentType = 'Adyen';
        if (this.isProcessing) {
            return;
        }
        this.isProcessing = true;
        this.refusalReasonCode = undefined;
        var arrLocaleCode = environment[global.env].arrAdyenLocaleCode;
        var currentLocale = arrLocaleCode[global.lang.toUpperCase()];
        var configuration = {
            locale: currentLocale,
            environment: environment[global.env].env,
            originKey: this.originKey,
            paymentMethodsResponse: this.paymentMethodsRespons,
        };

        var checkout = new AdyenCheckout(configuration);
        let self = this;
        this.uniqueId = uuid.v4();
        const locale = global.lang.toUpperCase();
        var paymentsDefaultConfig = {
            application: 'IPHONE_APP',
            data: {
                oemCode: environment[global.env].oemCode,
                paymentMethod: this.orderData.PaymentMethod,
                // shopperReference: 'reference',
                reference: this.orderData.OrderNumber,
                uniqueKey: this.uniqueId,
                appVersion: "ONL v 1.0.0",
                countryCode: this.orderData.BillCountry,
                shopperLocale: environment[global.env].arrAdyenLocaleCode[locale],
                returnUrl: location.origin + global.urlSuffix + '/' + this.myOrderPageUrl,
                amount: {
                    value: this.orderData.paidAmount * 100,
                    currency: this.orderData.currencyCode
                }
            }
        };

        var dropin = checkout.create('dropin', {
            paymentMethodsConfiguration: {
                applepay: { // Example required configuration for Apple Pay
                    configuration: {
                        merchantName: 'Adyen Test merchant', // Name to be displayed on the form
                        merchantIdentifier: 'adyen.test.merchant' // Your Apple merchant identifier as described in https://developer.apple.com/documentation/apple_pay_on_the_web/applepayrequest/2951611-merchantidentifier
                    }
                },
                paywithgoogle: { // Example required configuration for Google Pay
                    environment: "TEST", // Change this to PRODUCTION when you're ready to accept live Google Pay payments
                    configuration: {
                        gatewayMerchantId: "YourCompanyOrMerchantAccount", // Your Adyen merchant or company account name. Remove this field in TEST.
                        merchantIdentifier: "12345678910111213141" // Required for PRODUCTION. Remove this field in TEST. Your Google Merchant ID as described in https://developers.google.com/pay/api/web/guides/test-and-deploy/deploy-production-environment#obtain-your-merchantID
                    }
                },
                card: { // Example optional configuration for Cards
                    hasHolderName: true,
                    holderNameRequired: true,
                    enableStoreDetails: true,
                    name: this.languageData.web.adyen_credit_card_tit
                }
            },
            onSubmit: (state, dropin) => {

                let btn = this.dropinElement.nativeElement.querySelector('button');
                btn.setAttribute("disabled", 'disabled');
                self.dataService.makePayment(state.data, paymentsDefaultConfig).subscribe((res: Response) => {
                    let resSTR = JSON.stringify(res);
                    let resJSON = JSON.parse(resSTR);

                    if (resJSON.resultCode == "Refused") {
                        this.ngZone.run(() => {
                            localStorage.setItem('Refused', resJSON.resultCode);
                            paymentsDefaultConfig.data.uniqueKey = uuid.v4();
                            this.refusalReasonCode = resJSON.refusalReasonCode;
                            btn.removeAttribute("disabled");
                        });
                        return;
                    }

                    if (resJSON.resultCode == 'Authorised') {
                        this.id = resJSON.pspReference;
                        localStorage.removeItem('Refused');
                        this.updateOrderStatus(this.orderData.OrderNumber);
                    }
                    else if (resJSON.resultCode == 'RedirectShopper') {
                        localStorage.removeItem('Refused');
                        localStorage.setItem('orderNo', this.orderData.OrderNumber);
                        localStorage.setItem('paymentType', this.orderData.paymentType);
                        if (resJSON.action.method == 'POST') {
                            localStorage.setItem('PaymentMethod', this.orderData.PaymentMethod);
                            localStorage.setItem('paymentData', resJSON.paymentData);
                            localStorage.setItem('MD', resJSON.redirect.data.MD);
                        }
                        dropin.handleAction(resJSON.action);
                    }
                })
            },
            onAdditionalDetails: (state, dropin) => {
                self.dataService.makeDetailsCall(state.data, '', '', '').subscribe((res: Response) => {
                    let resSTR = JSON.stringify(res);
                    let resJSON = JSON.parse(resSTR);
                    dropin.handleAction(resJSON.action);
                })
            },
            onValidateMerchant: (resolve, reject, validationURL) => {
                // Call the validation endpoint with validationURL.
                // Call resolve(MERCHANTSESSION) or reject() to complete merchant validation.
            }
        })
            .mount('#dropin');
    }

    // PAYPAL CODE START
    private initConfig(): void {
        var arrLocaleCode = environment[global.env].arrPaypalLocaleCode;
        var currentLocale = arrLocaleCode[global.lang.toUpperCase()];
        var web = this.languageData.web;
        var paymentTransDesc = web['paypal_transaction_description'];
        //var currentLocale = arrLocaleCode['ZH-HK'];
        this.payPalConfig = {
            currency: environment[global.env].currency,
            // clientId: "Ad1Er7v_9ZpUOTdq_FK-9GdJsjm8X1edpcaLL9LVsPdg8yl-cYkD3zev6WxVYfWPhYD_XQdVKHdk07SU",
            clientId: "AY6RIGkTkDIwnjZIqVtGORJc8GbspfaMRLBIwHvub3gMZFgxW00u8HxTBNueZih01-k4AoorkTPREygE",
            createOrderOnClient: (data) => <ICreateOrderRequest>{
                intent: 'CAPTURE',
                locale: currentLocale,
                purchase_units: [
                    {
                        amount: {
                            currency_code: this.orderData.currencyCode,
                            value: this.orderData.paidAmount,
                            breakdown: {
                                item_total: {
                                    currency_code: this.orderData.currencyCode,
                                    value: this.orderData.paidAmount
                                }
                            }
                        },
                        items: [
                            {
                                name: paymentTransDesc,
                                quantity: '1',
                                category: 'DIGITAL_GOODS',
                                unit_amount: {
                                    currency_code: this.orderData.currencyCode,
                                    value: this.orderData.paidAmount
                                },
                            }]
                    }]
            },
            advanced: {
                commit: 'true',
            },
            style: {
                label: 'paypal',
                layout: 'horizontal',
                // size: 'responsive',
                height: 40,
                shape: 'rect',
                color: 'gold',
                tagline: false,
            },

            onApprove: (data, actions) => {
                actions.order.get().then(details => {
                });
            },
            onClientAuthorization: (data) => {

                var obj: any = data['purchase_units'][0];
                if (obj.payments.captures[0].status == "COMPLETED") {
                    this.id = obj.captures[0].id;
                    var paymentMethod = 'PayPal';
                    this.updatePaymentOrderStatus(this.orderData.OrderNumber, paymentMethod, 'paid', this.id);
                }
                this.showSuccess = true;
            },
            onCancel: (data, actions) => {
                // this.restoreInCartAfterPayment();
            },
            onError: err => {
            },
            onClick: (data, actions) => {
            },
        };
    }
    // PAYPAL CODE END

    closeModal() {
        this.dispalyPaypalBtn = false;
        this.activeModal.close('Modal Closed');
    }

}
