<div class="modal-content p-3" *ngIf="!isDisplayAdyenDropIn">
    <div class="modal-header bg-transparent px-0 pt-0 pb-3"> 
        <h3>{{'web.myorder_select_payment_option' | translate}}</h3>
        <a class="close" (click)="closeModal()" aria-label="Close">
            <img src="https://cdn.sw.fit/FS-AT/web-assets/close.svg" alt="" title="">
        </a>
    </div>
    <div class="modal-body px-0">
        <div class="loader">                                                                              
            <!-- <img src="{{colourfulSpinner}}" alt="" title=""/> -->
            <video playsinline autoplay muted src="{{colourfulSpinner}}"></video>
        </div>
        <div *ngIf="isDisplayPaymentOptions && isPaymentOptions">
            <div class="invoice-btn" (click)="openInvoiceModal()" *ngIf="dispalyInvoiceBtn == true">
                <img src="assets/images/invoice-btn.png" alt="" title="">
                <small class="align-sub">{{'web.checkout_payment_invoice_btn'| translate}}</small>
                <span class="spinner-border spinner-border-sm" role="status"
                    *ngIf="isProcessing && currentPaymentType == 'Invoice'" aria-hidden="true"></span>
            </div>
            <div class="shop-btn" (click)="openInShopModal()" *ngIf="dispalyInShopBtn == true">
                <img src="assets/images/shop_white.png" alt="" title="">
                <small class="align-sub">{{'web.checkout_payment_inshop_btn'| translate}}</small>
                <span class="spinner-border spinner-border-sm" role="status"
                    *ngIf="isProcessing && currentPaymentType == 'In-Shop'" aria-hidden="true"></span>
            </div>
            <div class="cc-btn" (click)="openAdyenModal(isPaymentType)" *ngIf="dispalyAdyenBtn == true">
                <span>
                    <img src="assets/images/CC_white.png" alt="" title="">
                    <span class="btn text-white p-0">{{'web.checkout_payment_others_options_btn'| translate}}</span>
                </span>
                <span class="spinner-border spinner-border-sm" role="status" 
                *ngIf="isProcessing && currentPaymentType == 'Adyen'" aria-hidden="true"></span>
            </div>
            <div class="adyen-btn" (click)="openAdyenModal(isPaymentType)" *ngIf="dispalyAdyenBtn == true">
                <img [src]="adyenBtnImagePath" alt="" title="">
            </div>
            <div  *ngIf="dispalyPaypalBtn == true" disabled="isProcessing">
                <ngx-paypal [config]="payPalConfig"></ngx-paypal>
            </div>
        </div>
    </div>
</div>

<div class="modal-content" [hidden]="!isDisplayAdyenDropIn">
     <div class="modal-header" >
        <h3 [hidden]="refusalReasonCode != undefined || paymentMethodsLength == 1">{{'web.dropin_payment_option_tit' | translate}}</h3>
        <p [hidden]="refusalReasonCode != '24'" class="text-danger">{{'web.refusal_reason_code_24' | translate}}</p>
        <p [hidden]="refusalReasonCode != '20'" class="text-danger">{{'web.refusal_reason_code_20' | translate}}</p>
        <p [hidden]="refusalReasonCode != '2'" class="text-danger">{{'web.refusal_reason_code_2' | translate}}</p>
        <a class="close" (click)="closeModal()"> <img src="https://cdn.sw.fit/FS-AT/web-assets/close.svg" alt="" title=""></a>
    </div>
    <div class="modal-body">
        <div id="dropin" #dropinModal>
            <!-- Drop-in will be rendered here -->
        </div>
    </div>
</div>

