import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountModule } from '../account/account.module';
import { TranslateModule } from '@ngx-translate/core';
import { MyaccountComponent } from '../account/myaccount/myaccount.component';
import { ProfileComponent } from '../account/profile/profile.component';
import { DeleteprofileComponent } from '../account/deleteprofile/deleteprofile.component';
import { NewsletterUnsubscribeComponent } from '../account/newsletter-unsubscribe/newsletter-unsubscribe.component';
import { OrderdetailModalComponent } from '../account/orderdetail-modal/orderdetail-modal.component';
import { PaymentOptionsModalComponent } from '../account/payment-options-modal/payment-options-modal.component';
import { MyorderDetailModalComponent } from '../account/myorder-detail-modal/myorder-detail-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AccountRoutingModule } from '../account/account-routing.module';
import { NgxPayPalModule } from 'ngx-paypal';
import { EmailSharedModule } from '../emailShared.module';
import { SharedModule } from '../shared/shared.module';
import { NgxStripeModule } from 'ngx-stripe';
import { CartRoutingModule } from '../cart/cart-routing.module';
import { AuthGuard } from '../guard/auth-guard.service';
import { DynamicPopsModalComponent } from '../cart/dynamic-pops-modal/dynamic-pops-modal.component';
import { CheckoutComponent } from '../cart/checkout/checkout.component';
import { MyaccountproileComponent } from '../account/myaccountproile/myaccountproile.component';
import { CartpageComponent } from '../cart/cartpage/cartpage.component';
import { UserDashboardComponent } from '../account/user-dashboard/user-dashboard.component';
import { SvgIconModule } from "../../lib/svg-icon.module";

@NgModule({
  declarations: [
    MyaccountComponent,
    ProfileComponent,
    DeleteprofileComponent,
    NewsletterUnsubscribeComponent,
    OrderdetailModalComponent,
    PaymentOptionsModalComponent,
    MyorderDetailModalComponent,
    DynamicPopsModalComponent,
    CheckoutComponent,
    MyaccountproileComponent,
    CartpageComponent,
    UserDashboardComponent
  ],

  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    NgbModule,
    AccountRoutingModule,
    NgxPayPalModule,
    EmailSharedModule,
    SharedModule,
    ReactiveFormsModule,
    NgxStripeModule.forRoot(),
    CartRoutingModule,
    SvgIconModule
],
  exports: [CheckoutComponent, DynamicPopsModalComponent],
  providers:[AuthGuard]

})
export class CustomCommonModule {}
