<div class="order-popup p-3">
    <div class="modal-header p-0 bg-transparent d-flex align-items-center">
        <p>{{'web.myorders_order_no' | translate}} <b>{{orderDetails.modalOrderNo}}</b>,   <span class="ps-2">{{orderDetailDate}}</span>
        </p>
        <span class="close-modal" (click)="closeModal()"><img src="{{popupCloseIcon}}" alt=""
                title="" /></span>
    </div>

    <div class="modal-body px-0 py-3 text-start">
        <div class="order-model">

            <div class="order-item" *ngFor="let project of orders;  let i = index">
                <!-- <pre>{{orders | json}}</pre> -->
                <div class="row g-0 mx-0">
                    <div class="col-12 col-md-4">
                        <figure>
                            <img [ngClass]="{'shadow': project.preview == 'PHOTOBOOK' || project.preview == 'DESKCALENDAR' ||  project.preview == 'BILET'
                                || project.preview == 'YEARCALENDAR' || project.preview == 'WALLCALENDAR ' || project.preview == 'CARD-SINGLE' || project.preview =='BIZCARD'}"
                                [src]="project.imageUrl" alt="" title="" *ngIf="!project.isIPL"/>
                                <img [ngClass]="{'shadow': project.preview == 'PHOTOBOOK' || project.preview == 'DESKCALENDAR' ||  project.preview == 'BILET'
                                || project.preview == 'YEARCALENDAR' || project.preview == 'WALLCALENDAR' || project.preview == 'CARD-SINGLE' || project.preview =='BIZCARD'}"
                                 [src]="project.previewURL" alt="" title="" *ngIf="project.isIPL">
                        </figure>
                        <div class="icons show-tooltip-down ps-3">
                            <h5 (click)="reOrderSingle(project)" *ngIf="!project.appVersion.includes('PhotoGenie')">
                                <img src="{{shopingBag}}" alt="" title="">
                                <span class="iconTitle">{{'web.myorders_reorder_icon_title' | translate}}</span>
                                <span class="top-tooltip">{{'web.myorders_reorder_cta_tooltip' | translate}}</span>
                            </h5>

                            <h5 (click)="copyProjectModal(i,project)" [hidden]="project.isCopyOrderDisable">
                                <img src="{{duplicateIcon}}" alt="" class="" title="">
                                <span class="iconTitle">{{'web.myprojects_copy_project__icon_title' | translate}}</span>
                                <span class="top-tooltip">{{'web.my_projects_copy_tool_tip' | translate}}</span>
                            </h5>
                        </div>

                    </div>
                    <div class="col-12 col-md-8">
                        <div class="order-details">
                            <div class="order-modal-title">{{project.online_text}}</div>
                            <ul class="order-detail-list">
                                <li>
                                    <span class="list-title">{{'web.myorders_quantity' | translate}}</span>
                                    <span class="list-value">{{project.quantity}}</span>
                                </li>
                                <li *ngIf="project.isPageCountVisible == true">
                                    <span class="list-title">{{'web.content_pages' | translate}}</span>
                                    <span class="list-value">{{project.page}}</span>
                                </li>
                                <li *ngIf="(project.totalImages != '' || project.totalImages != null) && project.totalImages!=0">
                                    <span class="list-title">{{'web.my_projects_product_photos' | translate}}</span>
                                    <span class="list-value">{{project.totalImages}}</span>
                                </li>
                                <li *ngIf="project.productName!=''">
                                    <span class="list-title">{{'web.checkout_project_name' | translate}}</span>
                                    <span class="list-value">{{project.productName}}</span>
                                </li>
                                <li *ngIf="project.isShowPaperName">
                                    <span class="list-title">{{'web.mycart_paper_finish' | translate}}</span>
                                    <span class="list-value">{{project.paperName}}</span>
                                </li>
                                <li>
                                    <span class="list-title">{{'web.my_cart_software_client'| translate}}</span>
                                    <span class="icon position-relative">
                                        <img class="ms-2 show-tooltip" [src]="project.clientType" alt="" 
                                        data-bs-toggle="tooltip" data-placement="top" title="" />
                                        <span class="top-tooltip">{{project.toolTipInfo}}</span>
                                    </span>
                                </li>
                                
                                <li *ngIf="project.trackingNumber && project.trackingNumber.includes('https') && project.orderStatus=='SH'">
                                    <span class="list-title">{{'web.track_my_order' | translate}}</span>
                                    <span class="list-value terms_condition_link">
                                        <a (click)="openNewTab(project.trackingNumber)" class="text-primary"> {{'web.show_the_tracking_info' | translate}} </a>
                                    </span>
                                </li>
                                
                                <li *ngIf="!(project.trackingNumber && project.trackingNumber.includes('https')) && project.orderStatus=='SH'">
                                    <span class="list-title">{{'web.track_my_order' | translate}}</span>
                                    <span class="list-value">{{'web.tracking_not_available' | translate}}</span>
                                </li>

                            </ul>
                        
                        </div>
                    </div>
                </div>
            </div>
            <div class="address-wrap">
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <div class="address">
                            <div class="address-title" *ngIf="shippingInfo.isPickup == 'No'">
                                {{'web.myorders_shipping_address' | translate}}</div>
                            <div class="address-title" *ngIf="shippingInfo.isPickup == 'Yes'">
                                {{'web.checkout_address_pickup_title' | translate}}</div>
                            <p>{{shippingInfo.ShipFirstName}} &nbsp;{{shippingInfo.ShipLastName}}</p>
                            <p>{{shippingInfo.ShipCompany}}</p>
                            <p>{{shippingInfo.ShipAddress1}}</p>
                            <p>{{shippingInfo.ShipAddress2}}</p>
                            <p>{{shippingInfo.ShipZip}} {{shippingInfo.ShipCity}}</p>
                            <p>{{shippingInfo.ShipCountryName}}</p>
                            <p>{{shippingInfo.ShipPhone}}</p>
                            <p>{{shippingInfo.ShipEmail}}</p>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <div class="address">
                            <div class="address-title">{{'web.myorders_billing_address' | translate}}</div>
                            <p>{{shippingInfo.BillFirstName}} &nbsp;{{shippingInfo.BillLastName}}</p>
                            <p>{{shippingInfo.BillCompany}}</p>
                            <p>{{shippingInfo.BillAddress1}}</p>
                            <p>{{shippingInfo.BillAddress2}}</p>
                            <p>{{shippingInfo.BillZip}} {{shippingInfo.BillCity}}</p>
                            <p>{{shippingInfo.BillCountryName}}</p>
                            <p>{{shippingInfo.BillPhone}}</p>
                            <p>{{shippingInfo.BillEmail}}</p>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>