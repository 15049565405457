<div class="order-popup">
    <div class="order-modal-header">
        <h3>{{'web.myorders_order_no' | translate}} {{orders[0].OrderNumber}},<span class="header_order_date">{{orderDetailDate}}</span></h3>
        <a class="close-modal" (click)="closeModal()"><img src="{{popupCloseIcon}}"  alt="" title=""/></a>
    </div>
    <div class="modal-body">
        <div class="order-detail-wrap" *ngFor="let project of orders; let i = index">
            <div class="row g-0 mx-0" >
                <div class="col-12 col-md-6 col-lg-5">
                    <figure>
                        <img [ngClass]="{'shadow': project.preview == 'PHOTOBOOK' || project.preview == 'DESKCALENDAR' ||  project.preview == 'BILET'
                        || project.preview == 'YEARCALENDAR' || project.preview == 'WALLCALENDAR ' || project.preview == 'CARD-SINGLE' || project.preview =='BIZCARD'}" 
                       [src]="project.imageUrl" crossorigin="anonymous" alt="" title="" />
                    </figure>
                </div>
                <div class="col-12 col-md-6 col-lg-7">
                    <p class="order-head">{{project.online_text}}</p>
                    <div class="order-info">
                        <span class="label">{{'web.myorders_quantity' | translate}}</span>
                        <span class="Value">{{project.quantity}}</span>
                    </div>
                    <div class="order-info" *ngIf="project.isPageCountVisible == true">
                        <span class="label">{{'web.content_pages' | translate}}</span>
                        <span class="Value">{{project.page}}</span>
                    </div>
                    <div class="order-info" *ngIf="project.totalImages != '' || project.totalImages != null">
                        <span class="label">{{'web.my_projects_product_photos' | translate}}</span>
                        <span class="Value">{{project.totalImages}}</span>
                    </div>
                    <div class="order-info" *ngIf="project.productName!=''">
                        <span class="label">{{'web.checkout_project_name' | translate}}</span>
                        <span class="Value">{{project.productName}}</span>
                    </div>
                    <div class="order-info"  *ngIf="project.themeInfo.themeName!=''">
                        <span class="label">{{'web.my_projects_applied_theme' | translate}}</span>
                        <span class="Value">{{project.themeInfo.themeName}}</span>
                    </div>
                    <div class="order-info" *ngIf="project.isShowPaperName">
                        <span class="label">{{'web.mycart_paper_finish' | translate}}</span>
                        <span class="Value">{{project.paperName}}</span>
                    </div>
                    <div class="order-info" *ngIf="project.isShowEnvelopName">
                        <span class="label">{{'web.checkout_options' | translate}}</span>
                        <span class="Value">{{project.envelopeName}}</span>
                    </div> 
                    <div class="order-info" >
                        <span class="label">{{'web.my_cart_software_client'| translate}}</span>
                        <span class="icon">
                            <img class="icon-tooltip-img" 
                            [src]="project.clientType"  alt="" crossorigin="anonymous" data-bs-toggle="tooltip" data-placement="top" title="{{project.toolTipInfo}}" />
                        </span>
                    </div>
                    <div class="order-info" *ngIf="project.finalUploadDate">
                        <span class="label">{{'web.order_detail_upload_date' | translate}}</span>
                        <span class="Value">{{finalUploadDate}}</span>
                    </div> 
                    <div class="action-btn">
                        <a [hidden]="orderDetail.hidereOrderBtn" class="btn btn-primary" (click)="reOrderSingle(project)">{{'web.myorders_reorder' | translate}}</a>
                        <a class="btn btn-primary" [hidden]="project.isCopyOrderDisable" (click)="copyProjectModal(i)">{{'web.my_orders_duplicate_order' | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="address-wrap" *ngIf="shippingInfo">
            <div class="row">
                <div class="col-12 col-md-6">
                    <h4 *ngIf="shippingInfo.isPickup == 'No'">{{'web.myorders_shipping_address' | translate}}</h4>
                    <h4 *ngIf="shippingInfo.isPickup == 'Yes'">{{'web.checkout_address_pickup_title' | translate}}</h4>
                    <div class="address-detail">
                        <p>{{shippingInfo.ShipFirstName}} &nbsp;{{shippingInfo.ShipLastName}}</p>
                        <p>{{shippingInfo.ShipCompany}}</p>
                        <p>{{shippingInfo.ShipAddress1}}</p>
                        <p>{{shippingInfo.ShipAddress2}}</p>
                        <p>{{shippingInfo.ShipZip}} {{shippingInfo.ShipCity}}</p>
                        <p>{{shippingInfo.ShipCountryName}}</p>
                        <p>{{shippingInfo.ShipPhone}}</p>
                        <p>{{shippingInfo.ShipEmail}}</p>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <h4>{{'web.myorders_billing_address' | translate}}</h4>
                    <div class="address-detail">
                        <p>{{shippingInfo.BillFirstName}} &nbsp;{{shippingInfo.BillLastName}}</p>
                        <p>{{shippingInfo.BillCompany}}</p>
                        <p>{{shippingInfo.BillAddress1}}</p>
                        <p>{{shippingInfo.BillAddress2}}</p>
                        <p>{{shippingInfo.BillZip}} {{shippingInfo.BillCity}}</p>
                        <p>{{shippingInfo.BillCountryName}}</p>
                        <p>{{shippingInfo.BillPhone}}</p>
                        <p>{{shippingInfo.BillEmail}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>