import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import * as global from 'src/app/globals';
import { GlobalService } from 'src/app/services/global.service';
import { DataService } from 'src/app/services/data.service';
import { ExternalService } from 'src/app/services/external.service';
import { DynamicPopupsModalComponent } from '../dynamic-popups-modal/dynamic-popups-modal.component';

declare var moment: any;
@Component({
    selector: 'app-myorder-detail-modal',
    templateUrl: './myorder-detail-modal.component.html',
    styleUrls: ['./myorder-detail-modal.component.scss'],
    standalone: false
})
export class MyorderDetailModalComponent implements OnInit {

  @Input() public orderDetails;
  orders: any;
  orderDetailDate: any;
  shippingInfo: any;
  albumIndex: any;
  orderIndex: any;
  arrOrderData: any = [];
  orderDetail: any;
  isCopyOrderDisable: Boolean = false;
  finalUploadDate: any;
  dicUrls: any;
  cartPageUrl: any;
  windowWidth: any;
  languageData: any;
  projectDataInfo: any = [];
  orderStatus: any;
  albumData: any = []
  orderItem: any = []
  shopingBag: any;
  popupCloseIcon: any;
  duplicateIcon: any;
  trackingNumber: any;
  constructor(private globalService: GlobalService, private dataService: DataService, private route: ActivatedRoute, private router: Router, public activeModal: NgbActiveModal, private modalService: NgbModal, private readonly svc: ExternalService) {
    let iconsObj = this.globalService.getIconsObj();
    this.popupCloseIcon = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/icons/checkmark-cross-32.svg';
    this.duplicateIcon = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/icons/duplicate-project-24-turq.svg';
    this.shopingBag = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/icons/shopping-cart-24-turq.svg';
  }

  ngOnInit() {
    this.windowWidth = window.innerWidth;
    this.svc.lazyMomentQuill().subscribe(_ => {
      this.setupQuill();
    });
  }

  openNewTab(url) {
    window.open(url, '_blank');
  }

  setupQuill() {
    this.orders = this.orderDetails.orders;
    this.languageData = this.orderDetails.languageData;

    this.dicUrls = this.globalService.getStaticPagesUrls(global.env, global.lang);
    this.cartPageUrl = this.dicUrls['CART_PREVIEW']

    if (environment[global.env].oemCode == 'CD-AT' || environment[global.env].oemCode == 'FS-AT') {
      this.orderDetailDate = moment(this.orders[0].date).format('DD.MM.YYYY HH:mm:ss');
    }
    else {
      this.orderDetailDate = this.orders[0].date;
    }
    if (this.orders[0].finalUploadDate) {
      this.finalUploadDate = moment(this.orders[0].finalUploadDate).format('DD.MM.YYYY HH:mm:ss');
    }
    this.shippingInfo = this.orders[0];
    this.orderIndex = this.orderDetails.orderIndex;
    this.arrOrderData = this.orderDetails.arrOrderData;
    this.orderDetail = this.arrOrderData;
    for (let i = 0; i < this.orders.length; i++) {

      if ((this.orders[i].appVersion.includes('ONL') || this.orders[i].appVersion.includes('EDIT') || this.orders[i].appVersion.includes('QUICK')) && this.orders[i].isRenderable == "Yes") {
        this.orders[i].isCopyOrderDisable = false;
      }
      else{
        this.orders[i].isCopyOrderDisable = true;
      }
      // if (this.orders[i].preview == 'PHOTOBOOK' || this.orders[i].preview == "MINIBOOK" || this.orders[i].preview == "PHOTOBOOK-" ||
      //   this.orders[i].preview == "SOFTBOOK" || this.orders[i].preview == "SOFTBOOK-" || this.orders[i].preview == "RINGBOOK" || this.orders[i].preview == "ALBUMBOOK" || this.orders[i].preview == "ALBUMBOOK-PRO-LINE" || this.orders[i].preview == 'DESKCALENDAR' ||
      //   this.orders[i].preview == 'YEARCALENDAR' || this.orders[i].preview == 'WALLCALENDAR') {
      //   // this.orders[i].isCopyOrderDisable = false;
      // }
      // else {
      //   // this.orders[i].isCopyOrderDisable = true;
      // }
      var albumInfo = this.orders[i];
      this.globalService.setAppVersionIcon(albumInfo, this.orders[i], this.languageData);

      if (albumInfo.isRenderable == 'No') {
        this.orders[i].imageUrl =
          environment[global.env].RESOURCE_URL_AMAZON +
          environment[global.env].AMAZON_IMAGE_FOLDER_PATH +
          albumInfo.productCode +
          'product_preview.png';

      }
      else if (albumInfo.Preview == 'PRINT' || albumInfo.Preview == 'RETRO') {
        this.orders[i].imageUrl =
          environment[global.env].RESOURCE_URL_AMAZON +
          environment[global.env].oemCode +
          '/web-assets/' +
          albumInfo.productCode +
          '-cart-thumb.png';

      } else {
        this.orders[i].imageUrl = this.arrOrderData.albumData[i].previewURL
        // this.orders[i].imageUrl =
        //   environment[global.env].RESOURCE_URL_AMAZON +
        //   environment[global.env].THUMB_BUCKET +
        //   albumInfo.albumCode +
        //   '/Thumb/' +
        //   albumInfo.thumbName;
      }
      this.orders[i].orderStatus = this.orderDetail.orderStatus;
      if (this.orders[i].appVersion.includes('PhotoGenie')) {
        this.orders[i].isIPL = true;
      } else {
        this.orders[i].isIPL = false;
      }
      this.orders[i].imageUrl =
        environment[global.env].RESOURCE_URL_AMAZON +
        environment[global.env].THUMB_BUCKET +
        this.orders[i].albumCode +
        '/Thumb/' +
        this.orders[i].thumbName;


    }

  }


  reOrderSingle(project) {
    var reOrderData = {
      "application": "IPHONE_APP",
      "data": { "cartData": { "items": [] } }
    }
    reOrderData.data.cartData.items.push({
      "albumCode": project.albumCode,
      "quantity": project.quantity,
      "productCode": project.productCode,
      "projectPrice": project.unitPrice,
      "paperCode": project.paperCode,
      "paperPrice": "0.00",
      "coverCode": project.coverCode,
      "coverPrice": "0.00",
      "envelopesCode": project.envelopesCode,
      "envelopesPrice": "0.00",
      "caseCode": project.caseCode,
      "casePrice": "0.00",
      "totalImages": project.totalImages,
      "totalPrints": project.totalPrints,
      "reorderId": project.OrderNumber
    })
    this.closeModal();
    this.dataService.addToCart(reOrderData).subscribe((res: Response) => {
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);
      if (resJSON.success == true) {
        this.router.navigate([this.cartPageUrl]);
      }
    })
  }

  copyProjectModal(index, project) {
    this.albumIndex = index;
    this.closeModal();
    this.openCopyOrderModal(project);
  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }

  openCopyOrderModal(projectData) {
    if (this.windowWidth < 576) {
      let title = this.languageData.web.myproject_duplicate_small_screen_popup_tit;
      let subTitle = this.languageData.web.myproject_duplicate_small_screen_popup_desc;
      let okBtn = this.languageData.web.myproject_duplicate_small_screen_popup_ok;
      const modalRef = this.modalService.open(DynamicPopupsModalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
      modalRef.componentInstance.data = { title: title, subTitle: subTitle, okBtnTxt: okBtn, cancelIcon: false };
    }
    else {
      let title = this.languageData.web.my_projects_copy_popup_project_title;
      let checkboxTxt = this.languageData.web.copy_id_pages_checkbox_text;
      let cancelBtn = this.languageData.web.my_projects_copy_popup_project_cancel_btn;
      let okBtn = this.languageData.web.my_projects_copy_popup_project_save_btn;
      const modalRef = this.modalService.open(DynamicPopupsModalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
      modalRef.componentInstance.data = { title: title, checkBoxtext: checkboxTxt, cancelBtnTxt: cancelBtn, okBtnTxt: okBtn, projectData: projectData, modalName: 'copyOrder', 'albumIndex': this.albumIndex, 'orderIndex': this.orderIndex, 'arrOrderData': this.arrOrderData, cancelIcon: true };
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = window.innerWidth;
  }


  openCopyModal(projectData) {
    if (this.windowWidth < 576) {
      let title = this.languageData.web.myproject_copy_small_screen_popup_tit;
      let subTitle = this.languageData.web.myproject_copy_small_screen_popup_desc;
      let okBtn = this.languageData.web.myproject_copy_small_screen_popup_ok;
      const modalRef = this.modalService.open(DynamicPopupsModalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
      modalRef.componentInstance.data = { title: title, subTitle: subTitle, okBtnTxt: okBtn, modalName: 'forMobile', cancelIcon: false };
    }
    else {
      let title = this.languageData.web.my_projects_copy_popup_project_title;
      let checkBoxtext = this.languageData.web.copy_id_pages_checkbox_text;
      let cancelBtn = this.languageData.web.my_projects_copy_popup_project_cancel_btn;
      let okBtn = this.languageData.web.my_projects_copy_popup_project_open_btn;
      const modalRef = this.modalService.open(DynamicPopupsModalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
      modalRef.componentInstance.data = {
        title: title, cancelBtnTxt: cancelBtn, checkBoxtext: checkBoxtext, okBtnTxt: okBtn, modalName: 'copyProject', cancelIcon: false,
        projectData: projectData
      };
    }
  }

  changeProductImage(albumIndex, index, albumData) {
    this.arrOrderData[index].currentQuantity = albumIndex + 1;
    this.arrOrderData[index].projectIndex = this.arrOrderData[index].currentQuantity - 1;
    albumData[albumIndex].selectedIndex = albumIndex;
    this.setImage(index);
  }

  setImage(index) {

    var albumInfo = this.arrOrderData[index].albumData[this.arrOrderData[index].projectIndex];
    this.globalService.setAppVersionIcon(albumInfo, this.arrOrderData[index], this.languageData);

    if (albumInfo.isRenderable == 'No') {
      this.arrOrderData[index].imageUrl =
        environment[global.env].RESOURCE_URL_AMAZON +
        environment[global.env].AMAZON_IMAGE_FOLDER_PATH +
        albumInfo.productCode +
        'product_preview.png';
    }
    else if (albumInfo.Preview == 'PRINT' || albumInfo.Preview == 'RETRO') {
      this.arrOrderData[index].imageUrl =
        environment[global.env].RESOURCE_URL_AMAZON +
        environment[global.env].oemCode +
        '/web-assets/' +
        albumInfo.productCode +
        '-cart-thumb.png';

    } else {
      
      this.arrOrderData[index].imageUrl =
        environment[global.env].RESOURCE_URL_AMAZON +
        environment[global.env].THUMB_BUCKET +
        albumInfo.albumcode +
        '/Thumb/' +
        albumInfo.thumbName;
    }
  }

}
